import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'isDatoCmsGif',
  pure: true,
  standalone: true,
})
export class DatoCmsGifPipe implements PipeTransform {
  transform(url?: string): boolean {
    return !!(url?.match(/datocms-assets/) && url?.match(/\.gif/))
  }
}
